<template>
  <v-snackbar
    v-model="opened"
    v-bind="$attrs"
    multi-line
  >
    {{ message }}

    <template v-slot:actions>
      <v-btn
        variant="text"
        @click="opened = false"
      >
        {{ $t(Words.OK) }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
const opened = ref(false)
const message = ref('')

function show(messageText: string) {
  message.value = messageText
  opened.value = true
}

defineExpose({
  show,
  opened,
})
</script>