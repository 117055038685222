import { createAction } from '@/utils/api'

import type {
  IMember,
  ISession,
  ISignupForm,
  ISignupResponse,
  ISignupError,
  ISigninResponse,
  ISigninRequest,
  ISigninError,
  IAuthorizedSales,
  IManageableSaleAccount,
  IManageableDemoAccount,
} from './types'

import { IPagination } from '@/api/types'

export * from './types'

// 測試子帳號管理
// import './mock_subaccount'

/**
 * 會員登入
 */
export const memberSignin = createAction<
  ISigninResponse,
  ISigninRequest,
  ISigninError
>('POST', '/api/:locale/members/signin')

export const memberSigninMock = createAction<
  ISigninResponse,
  ISigninRequest
>('POST', '/api/:locale/members/signin?mock=1')

/**
 * 會員登出
 */
export const memberSignout = createAction<
  {}
>('POST', '/api/:locale/members/signout')


/**
 * 註冊會員，或是檢查註冊資訊
 */
export const memberSignup = createAction<
  // Response
  ISignupResponse,
  ISignupForm,
  ISignupError
>('POST', '/api/:locale/members/signup')

/**
 * 取得會員資料
 */
export const memberAuthorization = createAction<
  // Response
  {
    member: IMember
    session: ISession
  }
>('GET', '/api/:locale/members/me')

/**
 * 更改密碼
 */
export const memberPasswd = createAction<
  // Response
  {
    message: string
  },
  {
    password_current: string
    password: string
    password_confirmation: string
  }
>('POST', '/api/:locale/members/passwd')

/**
 * 忘記密碼：申請
 */
export const memberForgetPassword = createAction<
  {
    message: string
  },
  {
    client_id: string
    client_name: string
  }
>('POST', '/api/:locale/members/forget')

/**
 * 忘記密碼：設定
 */
export const memberForgetPasswordReset = createAction<
  {
    message: string
  },
  {
    usercode: string
    token: string
    password?: string
    password_confirmation?: string
  }
>('POST', '/api/:locale/members/forget')

/**
 * 子帳號：取得此Agent的帳號可管理狀態
 */
export const loadSubAccountSettings = createAction<
  {
    /** 允許新帳號設立的網域 */
    allowed_domains: string[]
    available_sales: IAuthorizedSales[]
  }
>('GET', '/api/:locale/subaccounts/settings')

/**
 * 子帳號：取得子帳號列表
 */
export const loadSubAccountSaleList = createAction<
  {
    members: IManageableSaleAccount[]
    pagination: IPagination
  },
  // Request
  {
    page?: number | null
  }
>('GET', '/api/:locale/subaccounts/list/sale')

/**
 * 子帳號：取得子帳號列表
 */
export const loadSubAccountDemoList = createAction<
  {
    members: IManageableDemoAccount[]
    pagination: IPagination
  },
  // Request
  {
    page?: number | null
  }
>('GET', '/api/:locale/subaccounts/list/demo')

/**
 * 子帳號：建立帳號
 */
export const createSubAccount = createAction<
  {
    message: string
  },
  {
    email: string
    // 以下兩個只能擇一
    sale?: IManageableSaleAccount
    demo?: IManageableDemoAccount
  }
>('POST', '/api/:locale/subaccounts/:email')

/**
 * 子帳號：更新帳號
 */
export const updateSubAccount = createAction<
  {
    message: string
  },
  {
    email: string
    // 以下兩個只能擇一
    sale?: IManageableSaleAccount
    demo?: IManageableDemoAccount
  }
>('POST', '/api/:locale/subaccounts/:email/update')

/**
 * 子帳號：帳號啟用/停用
 */
export const toggleSubAccount = createAction<
  {
    message: string
  },
  {
    email: string
    activate: boolean
  }
>('POST', '/api/:locale/subaccounts/:email/toggle')