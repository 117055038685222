/**
 * 會自動偵測瀏覽器已開啟的頁籤數量，並當全部頁籤關閉時自動登出
 */
export default defineNuxtPlugin((nuxtApp) => {

  // nuxtApp.hooks.hookOnce('page:finish', () => {

  //   const {
  //     hookMounted,
  //   } = useSessionStore()

  //   hookMounted()

  // })

})
