/**
 * 會員存取權限對照表
 */
export enum Privilege {

  /**
   * 測試用空包彈，沒有任何人會符合這個條件
   */
  NONE = 'none',

  /**
   * 01 庫存查詢
   */
  Assets = 'mnu01',
  /** 庫存查詢 > 優璟系列基金 */
  Assets_UGFunds = 'mnu0101',
  /** 庫存查詢 > 目前庫存 */
  Assets_Inventory = 'mnu010101',
  /** 庫存查詢 > 歷史交易總覽 */
  Assets_History = 'mnu010102',
  /** 庫存查詢 > 累計損益 */
  Assets_Pnl = 'mnu010103',
  /** 庫存查詢 > 帳戶資訊 */
  Assets_Account = 'mnu010104',
  /** 庫存查詢 > 潛龍低估資產一號 */
  Assets_Hduat1 = 'mnu0102',

  /**
   * 02 淨值績效
   */
  Prices = 'mnu02',
  /** 淨值績效 > 2023年4月正式淨值公告 */
  Prices_Latest = 'mnu0201',
  /** 淨值績效 > 2023年4月預估淨值公告 */
  Prices_Estimate = 'mnu0202',

  /**
   * 03 定期報告
   */
  Reports = 'mnu03',
  /** 定期報告 > 每日中國重大要聞 */
  Reports_ChinaNews = 'mnu0301',
  /** 定期報告 > 2023年3月基金報告 */
  Reports_FundLatest = 'mnu0302',
  /** 定期報告 > 歷史淨值表 */
  Reports_PricesHistory = 'mnu0303',
  /** 定期報告 > 2023年3月總經圖表 */
  Reports_ChartLatest = 'mnu0304',
  /** 定期報告 > 2021/2022年度財務報告 */
  Reports_FinancialReport = 'mnu0305',

  /**
   * 04 重要公告
   */
  Notices = 'mnu04',

  /**
   * 05 經理人觀點
   */
  Comments = 'mnu05',

  /**
   * 06 交易表格下載專區
   */
  Forms = 'mnu06',
  /** 交易表格下載專區 > 認購區 */
  Forms_Subscription = 'mnu0601',
  /** 交易表格下載專區 > 贖回區 */
  Forms_Redemption = 'mnu0602',
  /** 交易表格下載專區 > 客戶資料變更區 */
  Forms_Customer = 'mnu0603',
  /** 交易表格下載專區 > Agent區 */
  Forms_Agent = 'mnu0604',
  
  /** 
   * 交易系統
   */

  /** 交易：查看交易管理 */
  TradeView = 'trade_view',
  /** 交易：遞單管理 > 審核  */
  TradeApprove = 'trade_approve',
  /** 交易：遞單管理、交易管理 > 建立交易  */
  TradeKeyin = 'trade_keyin',

  /** 
   * 子帳號系統新增的角色權限
   */

  /** 子帳號/demo帳號的權限管理 */
  SubAccountManage = 'sub-account_manage',

  /** 分潤總覽 */
  RebateOverview = 'rebate_overview',

  /**
   * 管理後台
   */

  /** 月報管理：列表 */
  FactSheetManage = 'fact-sheet_manage',
  /** 月報管理：限定版 */
  FactSheetLimitedManage = 'fact-sheet_limited_manage'

}