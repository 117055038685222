import { default as _404yiNO2ilnTEMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/404.vue?macro=true";
import { default as _5006nvNCNjVR3Meta } from "/home/ugadmin/UGFundWebSite/frontend/pages/500.vue?macro=true";
import { default as indexu7ao6GTJ0VMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/admin/index.vue?macro=true";
import { default as _91_46_46_46slug_93cKKPfmEKNoMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/browse/[...slug].vue?macro=true";
import { default as indexLl93UrahVtMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/browse/index.vue?macro=true";
import { default as _91_46_46_46slug_93oviBB3PsM0Meta } from "/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/edit/[...slug].vue?macro=true";
import { default as _91_91time_93_93HIPaaRUHk2Meta } from "/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/limited/[[time]].vue?macro=true";
import { default as announcementWaBqrwZkTmMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/announcement.vue?macro=true";
import { default as awards0qhwrEBhsuMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/awards.vue?macro=true";
import { default as _91id_93fIUq4fszVRMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/comments/[id].vue?macro=true";
import { default as indexDtzXS91MIDMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/comments/index.vue?macro=true";
import { default as chatbotLtTtpmPY4ZMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/demo/chatbot.vue?macro=true";
import { default as _2023vipvsATLzJeYaMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/events/2023vip.vue?macro=true";
import { default as exampleqsqLNfVZoVMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/example.vue?macro=true";
import { default as formsvKmuMScVXCMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/forms.vue?macro=true";
import { default as _91id_93wVTszsXid2Meta } from "/home/ugadmin/UGFundWebSite/frontend/pages/funds/[id].vue?macro=true";
import { default as indexhj9CSPFgRCMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/funds/index.vue?macro=true";
import { default as indexw048l0l5VoMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/index.vue?macro=true";
import { default as templatepsqyqBFYKSMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/internal/fundReport/template.vue?macro=true";
import { default as _91_91type_93_93J8UhaDtAMeMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/manage/[[type]].vue?macro=true";
import { default as meetingtAXtrDUHjyMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/meeting.vue?macro=true";
import { default as _91token_934nx9suzrevMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/member/forget/[usercode]/[token].vue?macro=true";
import { default as indexuvi73LQD2FMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/member/forget/index.vue?macro=true";
import { default as _91otpCode_93AikG8LU3qAMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/member/otp-signin/[usercode]/[otpCode].vue?macro=true";
import { default as passwd07uH0fygbUMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/member/passwd.vue?macro=true";
import { default as signinWD9DmTfSyBMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/member/signin.vue?macro=true";
import { default as signuplY0u67vh8KMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/member/signup.vue?macro=true";
import { default as assetlpiDOQ9TaAMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/my/asset.vue?macro=true";
import { default as chargeawCy48nGOkMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/my/charge.vue?macro=true";
import { default as clientsnhKGAlW0JpMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/my/clients.vue?macro=true";
import { default as _91_91type_93_93yRPg6Ek1CiMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/my/dealing/[[type]].vue?macro=true";
import { default as historyETLBzcGhCnMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/my/history.vue?macro=true";
import { default as notify_45_91id_93AD3045aJaDMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/my/notify-[id].vue?macro=true";
import { default as _91_91tab_93_93aOz5qxVN9NMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/my/notify/[[tab]].vue?macro=true";
import { default as newsxjynT9mNoaMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/news.vue?macro=true";
import { default as recruita9ILAneq1ZMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/recruit.vue?macro=true";
import { default as _91_91tab_93_938Gf8csMQcZMeta } from "/home/ugadmin/UGFundWebSite/frontend/pages/sub-accounts/[[tab]].vue?macro=true";
export default [
  {
    name: _404yiNO2ilnTEMeta?.name ?? "404___en",
    path: _404yiNO2ilnTEMeta?.path ?? "/en/404",
    meta: _404yiNO2ilnTEMeta || {},
    alias: _404yiNO2ilnTEMeta?.alias || [],
    redirect: _404yiNO2ilnTEMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404yiNO2ilnTEMeta?.name ?? "404",
    path: _404yiNO2ilnTEMeta?.path ?? "/404",
    meta: _404yiNO2ilnTEMeta || {},
    alias: _404yiNO2ilnTEMeta?.alias || [],
    redirect: _404yiNO2ilnTEMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404yiNO2ilnTEMeta?.name ?? "404___zh",
    path: _404yiNO2ilnTEMeta?.path ?? "/zh/404",
    meta: _404yiNO2ilnTEMeta || {},
    alias: _404yiNO2ilnTEMeta?.alias || [],
    redirect: _404yiNO2ilnTEMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _5006nvNCNjVR3Meta?.name ?? "500___en",
    path: _5006nvNCNjVR3Meta?.path ?? "/en/500",
    meta: _5006nvNCNjVR3Meta || {},
    alias: _5006nvNCNjVR3Meta?.alias || [],
    redirect: _5006nvNCNjVR3Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/500.vue").then(m => m.default || m)
  },
  {
    name: _5006nvNCNjVR3Meta?.name ?? "500",
    path: _5006nvNCNjVR3Meta?.path ?? "/500",
    meta: _5006nvNCNjVR3Meta || {},
    alias: _5006nvNCNjVR3Meta?.alias || [],
    redirect: _5006nvNCNjVR3Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/500.vue").then(m => m.default || m)
  },
  {
    name: _5006nvNCNjVR3Meta?.name ?? "500___zh",
    path: _5006nvNCNjVR3Meta?.path ?? "/zh/500",
    meta: _5006nvNCNjVR3Meta || {},
    alias: _5006nvNCNjVR3Meta?.alias || [],
    redirect: _5006nvNCNjVR3Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/500.vue").then(m => m.default || m)
  },
  {
    name: indexu7ao6GTJ0VMeta?.name ?? "admin___en",
    path: indexu7ao6GTJ0VMeta?.path ?? "/en/admin",
    meta: indexu7ao6GTJ0VMeta || {},
    alias: indexu7ao6GTJ0VMeta?.alias || [],
    redirect: indexu7ao6GTJ0VMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexu7ao6GTJ0VMeta?.name ?? "admin",
    path: indexu7ao6GTJ0VMeta?.path ?? "/admin",
    meta: indexu7ao6GTJ0VMeta || {},
    alias: indexu7ao6GTJ0VMeta?.alias || [],
    redirect: indexu7ao6GTJ0VMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexu7ao6GTJ0VMeta?.name ?? "admin___zh",
    path: indexu7ao6GTJ0VMeta?.path ?? "/zh/admin",
    meta: indexu7ao6GTJ0VMeta || {},
    alias: indexu7ao6GTJ0VMeta?.alias || [],
    redirect: indexu7ao6GTJ0VMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93cKKPfmEKNoMeta?.name ?? "admin-reports-browse-slug___en",
    path: _91_46_46_46slug_93cKKPfmEKNoMeta?.path ?? "/en/admin/reports/browse/:slug(.*)*",
    meta: _91_46_46_46slug_93cKKPfmEKNoMeta || {},
    alias: _91_46_46_46slug_93cKKPfmEKNoMeta?.alias || [],
    redirect: _91_46_46_46slug_93cKKPfmEKNoMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/browse/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93cKKPfmEKNoMeta?.name ?? "admin-reports-browse-slug",
    path: _91_46_46_46slug_93cKKPfmEKNoMeta?.path ?? "/admin/reports/browse/:slug(.*)*",
    meta: _91_46_46_46slug_93cKKPfmEKNoMeta || {},
    alias: _91_46_46_46slug_93cKKPfmEKNoMeta?.alias || [],
    redirect: _91_46_46_46slug_93cKKPfmEKNoMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/browse/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93cKKPfmEKNoMeta?.name ?? "admin-reports-browse-slug___zh",
    path: _91_46_46_46slug_93cKKPfmEKNoMeta?.path ?? "/zh/admin/reports/browse/:slug(.*)*",
    meta: _91_46_46_46slug_93cKKPfmEKNoMeta || {},
    alias: _91_46_46_46slug_93cKKPfmEKNoMeta?.alias || [],
    redirect: _91_46_46_46slug_93cKKPfmEKNoMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/browse/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexLl93UrahVtMeta?.name ?? "admin-reports-browse___en",
    path: indexLl93UrahVtMeta?.path ?? "/en/admin/reports/browse",
    meta: indexLl93UrahVtMeta || {},
    alias: indexLl93UrahVtMeta?.alias || [],
    redirect: indexLl93UrahVtMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/browse/index.vue").then(m => m.default || m)
  },
  {
    name: indexLl93UrahVtMeta?.name ?? "admin-reports-browse",
    path: indexLl93UrahVtMeta?.path ?? "/admin/reports/browse",
    meta: indexLl93UrahVtMeta || {},
    alias: indexLl93UrahVtMeta?.alias || [],
    redirect: indexLl93UrahVtMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/browse/index.vue").then(m => m.default || m)
  },
  {
    name: indexLl93UrahVtMeta?.name ?? "admin-reports-browse___zh",
    path: indexLl93UrahVtMeta?.path ?? "/zh/admin/reports/browse",
    meta: indexLl93UrahVtMeta || {},
    alias: indexLl93UrahVtMeta?.alias || [],
    redirect: indexLl93UrahVtMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/browse/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93oviBB3PsM0Meta?.name ?? "admin-reports-edit-slug___en",
    path: _91_46_46_46slug_93oviBB3PsM0Meta?.path ?? "/en/admin/reports/edit/:slug(.*)*",
    meta: _91_46_46_46slug_93oviBB3PsM0Meta || {},
    alias: _91_46_46_46slug_93oviBB3PsM0Meta?.alias || [],
    redirect: _91_46_46_46slug_93oviBB3PsM0Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/edit/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93oviBB3PsM0Meta?.name ?? "admin-reports-edit-slug",
    path: _91_46_46_46slug_93oviBB3PsM0Meta?.path ?? "/admin/reports/edit/:slug(.*)*",
    meta: _91_46_46_46slug_93oviBB3PsM0Meta || {},
    alias: _91_46_46_46slug_93oviBB3PsM0Meta?.alias || [],
    redirect: _91_46_46_46slug_93oviBB3PsM0Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/edit/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93oviBB3PsM0Meta?.name ?? "admin-reports-edit-slug___zh",
    path: _91_46_46_46slug_93oviBB3PsM0Meta?.path ?? "/zh/admin/reports/edit/:slug(.*)*",
    meta: _91_46_46_46slug_93oviBB3PsM0Meta || {},
    alias: _91_46_46_46slug_93oviBB3PsM0Meta?.alias || [],
    redirect: _91_46_46_46slug_93oviBB3PsM0Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/edit/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91time_93_93HIPaaRUHk2Meta?.name ?? "admin-reports-limited-time___en",
    path: _91_91time_93_93HIPaaRUHk2Meta?.path ?? "/en/admin/reports/limited/:time?",
    meta: _91_91time_93_93HIPaaRUHk2Meta || {},
    alias: _91_91time_93_93HIPaaRUHk2Meta?.alias || [],
    redirect: _91_91time_93_93HIPaaRUHk2Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/limited/[[time]].vue").then(m => m.default || m)
  },
  {
    name: _91_91time_93_93HIPaaRUHk2Meta?.name ?? "admin-reports-limited-time",
    path: _91_91time_93_93HIPaaRUHk2Meta?.path ?? "/admin/reports/limited/:time?",
    meta: _91_91time_93_93HIPaaRUHk2Meta || {},
    alias: _91_91time_93_93HIPaaRUHk2Meta?.alias || [],
    redirect: _91_91time_93_93HIPaaRUHk2Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/limited/[[time]].vue").then(m => m.default || m)
  },
  {
    name: _91_91time_93_93HIPaaRUHk2Meta?.name ?? "admin-reports-limited-time___zh",
    path: _91_91time_93_93HIPaaRUHk2Meta?.path ?? "/zh/admin/reports/limited/:time?",
    meta: _91_91time_93_93HIPaaRUHk2Meta || {},
    alias: _91_91time_93_93HIPaaRUHk2Meta?.alias || [],
    redirect: _91_91time_93_93HIPaaRUHk2Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/admin/reports/limited/[[time]].vue").then(m => m.default || m)
  },
  {
    name: announcementWaBqrwZkTmMeta?.name ?? "announcement___en",
    path: announcementWaBqrwZkTmMeta?.path ?? "/en/announcement",
    meta: announcementWaBqrwZkTmMeta || {},
    alias: announcementWaBqrwZkTmMeta?.alias || [],
    redirect: announcementWaBqrwZkTmMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/announcement.vue").then(m => m.default || m)
  },
  {
    name: announcementWaBqrwZkTmMeta?.name ?? "announcement",
    path: announcementWaBqrwZkTmMeta?.path ?? "/announcement",
    meta: announcementWaBqrwZkTmMeta || {},
    alias: announcementWaBqrwZkTmMeta?.alias || [],
    redirect: announcementWaBqrwZkTmMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/announcement.vue").then(m => m.default || m)
  },
  {
    name: announcementWaBqrwZkTmMeta?.name ?? "announcement___zh",
    path: announcementWaBqrwZkTmMeta?.path ?? "/zh/announcement",
    meta: announcementWaBqrwZkTmMeta || {},
    alias: announcementWaBqrwZkTmMeta?.alias || [],
    redirect: announcementWaBqrwZkTmMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/announcement.vue").then(m => m.default || m)
  },
  {
    name: awards0qhwrEBhsuMeta?.name ?? "awards___en",
    path: awards0qhwrEBhsuMeta?.path ?? "/en/awards",
    meta: awards0qhwrEBhsuMeta || {},
    alias: awards0qhwrEBhsuMeta?.alias || [],
    redirect: awards0qhwrEBhsuMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awards0qhwrEBhsuMeta?.name ?? "awards",
    path: awards0qhwrEBhsuMeta?.path ?? "/awards",
    meta: awards0qhwrEBhsuMeta || {},
    alias: awards0qhwrEBhsuMeta?.alias || [],
    redirect: awards0qhwrEBhsuMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awards0qhwrEBhsuMeta?.name ?? "awards___zh",
    path: awards0qhwrEBhsuMeta?.path ?? "/zh/awards",
    meta: awards0qhwrEBhsuMeta || {},
    alias: awards0qhwrEBhsuMeta?.alias || [],
    redirect: awards0qhwrEBhsuMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fIUq4fszVRMeta?.name ?? "comments-id___en",
    path: _91id_93fIUq4fszVRMeta?.path ?? "/en/comments/:id()",
    meta: _91id_93fIUq4fszVRMeta || {},
    alias: _91id_93fIUq4fszVRMeta?.alias || [],
    redirect: _91id_93fIUq4fszVRMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/comments/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93fIUq4fszVRMeta?.name ?? "comments-id",
    path: _91id_93fIUq4fszVRMeta?.path ?? "/comments/:id()",
    meta: _91id_93fIUq4fszVRMeta || {},
    alias: _91id_93fIUq4fszVRMeta?.alias || [],
    redirect: _91id_93fIUq4fszVRMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/comments/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93fIUq4fszVRMeta?.name ?? "comments-id___zh",
    path: _91id_93fIUq4fszVRMeta?.path ?? "/zh/comments/:id()",
    meta: _91id_93fIUq4fszVRMeta || {},
    alias: _91id_93fIUq4fszVRMeta?.alias || [],
    redirect: _91id_93fIUq4fszVRMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/comments/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDtzXS91MIDMeta?.name ?? "comments___en",
    path: indexDtzXS91MIDMeta?.path ?? "/en/comments",
    meta: indexDtzXS91MIDMeta || {},
    alias: indexDtzXS91MIDMeta?.alias || [],
    redirect: indexDtzXS91MIDMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/comments/index.vue").then(m => m.default || m)
  },
  {
    name: indexDtzXS91MIDMeta?.name ?? "comments",
    path: indexDtzXS91MIDMeta?.path ?? "/comments",
    meta: indexDtzXS91MIDMeta || {},
    alias: indexDtzXS91MIDMeta?.alias || [],
    redirect: indexDtzXS91MIDMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/comments/index.vue").then(m => m.default || m)
  },
  {
    name: indexDtzXS91MIDMeta?.name ?? "comments___zh",
    path: indexDtzXS91MIDMeta?.path ?? "/zh/comments",
    meta: indexDtzXS91MIDMeta || {},
    alias: indexDtzXS91MIDMeta?.alias || [],
    redirect: indexDtzXS91MIDMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/comments/index.vue").then(m => m.default || m)
  },
  {
    name: chatbotLtTtpmPY4ZMeta?.name ?? "demo-chatbot___en",
    path: chatbotLtTtpmPY4ZMeta?.path ?? "/en/demo/chatbot",
    meta: chatbotLtTtpmPY4ZMeta || {},
    alias: chatbotLtTtpmPY4ZMeta?.alias || [],
    redirect: chatbotLtTtpmPY4ZMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/demo/chatbot.vue").then(m => m.default || m)
  },
  {
    name: chatbotLtTtpmPY4ZMeta?.name ?? "demo-chatbot",
    path: chatbotLtTtpmPY4ZMeta?.path ?? "/demo/chatbot",
    meta: chatbotLtTtpmPY4ZMeta || {},
    alias: chatbotLtTtpmPY4ZMeta?.alias || [],
    redirect: chatbotLtTtpmPY4ZMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/demo/chatbot.vue").then(m => m.default || m)
  },
  {
    name: chatbotLtTtpmPY4ZMeta?.name ?? "demo-chatbot___zh",
    path: chatbotLtTtpmPY4ZMeta?.path ?? "/zh/demo/chatbot",
    meta: chatbotLtTtpmPY4ZMeta || {},
    alias: chatbotLtTtpmPY4ZMeta?.alias || [],
    redirect: chatbotLtTtpmPY4ZMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/demo/chatbot.vue").then(m => m.default || m)
  },
  {
    name: _2023vipvsATLzJeYaMeta?.name ?? "events-2023vip___en",
    path: _2023vipvsATLzJeYaMeta?.path ?? "/en/events/2023vip",
    meta: _2023vipvsATLzJeYaMeta || {},
    alias: _2023vipvsATLzJeYaMeta?.alias || [],
    redirect: _2023vipvsATLzJeYaMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/events/2023vip.vue").then(m => m.default || m)
  },
  {
    name: _2023vipvsATLzJeYaMeta?.name ?? "events-2023vip",
    path: _2023vipvsATLzJeYaMeta?.path ?? "/events/2023vip",
    meta: _2023vipvsATLzJeYaMeta || {},
    alias: _2023vipvsATLzJeYaMeta?.alias || [],
    redirect: _2023vipvsATLzJeYaMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/events/2023vip.vue").then(m => m.default || m)
  },
  {
    name: _2023vipvsATLzJeYaMeta?.name ?? "events-2023vip___zh",
    path: _2023vipvsATLzJeYaMeta?.path ?? "/zh/events/2023vip",
    meta: _2023vipvsATLzJeYaMeta || {},
    alias: _2023vipvsATLzJeYaMeta?.alias || [],
    redirect: _2023vipvsATLzJeYaMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/events/2023vip.vue").then(m => m.default || m)
  },
  {
    name: exampleqsqLNfVZoVMeta?.name ?? "example___en",
    path: exampleqsqLNfVZoVMeta?.path ?? "/en/example",
    meta: exampleqsqLNfVZoVMeta || {},
    alias: exampleqsqLNfVZoVMeta?.alias || [],
    redirect: exampleqsqLNfVZoVMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/example.vue").then(m => m.default || m)
  },
  {
    name: exampleqsqLNfVZoVMeta?.name ?? "example",
    path: exampleqsqLNfVZoVMeta?.path ?? "/example",
    meta: exampleqsqLNfVZoVMeta || {},
    alias: exampleqsqLNfVZoVMeta?.alias || [],
    redirect: exampleqsqLNfVZoVMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/example.vue").then(m => m.default || m)
  },
  {
    name: exampleqsqLNfVZoVMeta?.name ?? "example___zh",
    path: exampleqsqLNfVZoVMeta?.path ?? "/zh/example",
    meta: exampleqsqLNfVZoVMeta || {},
    alias: exampleqsqLNfVZoVMeta?.alias || [],
    redirect: exampleqsqLNfVZoVMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/example.vue").then(m => m.default || m)
  },
  {
    name: formsvKmuMScVXCMeta?.name ?? "forms___en",
    path: formsvKmuMScVXCMeta?.path ?? "/en/forms",
    meta: formsvKmuMScVXCMeta || {},
    alias: formsvKmuMScVXCMeta?.alias || [],
    redirect: formsvKmuMScVXCMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/forms.vue").then(m => m.default || m)
  },
  {
    name: formsvKmuMScVXCMeta?.name ?? "forms",
    path: formsvKmuMScVXCMeta?.path ?? "/forms",
    meta: formsvKmuMScVXCMeta || {},
    alias: formsvKmuMScVXCMeta?.alias || [],
    redirect: formsvKmuMScVXCMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/forms.vue").then(m => m.default || m)
  },
  {
    name: formsvKmuMScVXCMeta?.name ?? "forms___zh",
    path: formsvKmuMScVXCMeta?.path ?? "/zh/forms",
    meta: formsvKmuMScVXCMeta || {},
    alias: formsvKmuMScVXCMeta?.alias || [],
    redirect: formsvKmuMScVXCMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/forms.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wVTszsXid2Meta?.name ?? "funds-id___en",
    path: _91id_93wVTszsXid2Meta?.path ?? "/en/funds/:id()",
    meta: _91id_93wVTszsXid2Meta || {},
    alias: _91id_93wVTszsXid2Meta?.alias || [],
    redirect: _91id_93wVTszsXid2Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/funds/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93wVTszsXid2Meta?.name ?? "funds-id",
    path: _91id_93wVTszsXid2Meta?.path ?? "/funds/:id()",
    meta: _91id_93wVTszsXid2Meta || {},
    alias: _91id_93wVTszsXid2Meta?.alias || [],
    redirect: _91id_93wVTszsXid2Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/funds/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93wVTszsXid2Meta?.name ?? "funds-id___zh",
    path: _91id_93wVTszsXid2Meta?.path ?? "/zh/funds/:id()",
    meta: _91id_93wVTszsXid2Meta || {},
    alias: _91id_93wVTszsXid2Meta?.alias || [],
    redirect: _91id_93wVTszsXid2Meta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/funds/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhj9CSPFgRCMeta?.name ?? "funds___en",
    path: indexhj9CSPFgRCMeta?.path ?? "/en/funds",
    meta: indexhj9CSPFgRCMeta || {},
    alias: indexhj9CSPFgRCMeta?.alias || [],
    redirect: indexhj9CSPFgRCMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: indexhj9CSPFgRCMeta?.name ?? "funds",
    path: indexhj9CSPFgRCMeta?.path ?? "/funds",
    meta: indexhj9CSPFgRCMeta || {},
    alias: indexhj9CSPFgRCMeta?.alias || [],
    redirect: indexhj9CSPFgRCMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: indexhj9CSPFgRCMeta?.name ?? "funds___zh",
    path: indexhj9CSPFgRCMeta?.path ?? "/zh/funds",
    meta: indexhj9CSPFgRCMeta || {},
    alias: indexhj9CSPFgRCMeta?.alias || [],
    redirect: indexhj9CSPFgRCMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: indexw048l0l5VoMeta?.name ?? "index___en",
    path: indexw048l0l5VoMeta?.path ?? "/en",
    meta: indexw048l0l5VoMeta || {},
    alias: indexw048l0l5VoMeta?.alias || [],
    redirect: indexw048l0l5VoMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexw048l0l5VoMeta?.name ?? "index",
    path: indexw048l0l5VoMeta?.path ?? "/",
    meta: indexw048l0l5VoMeta || {},
    alias: indexw048l0l5VoMeta?.alias || [],
    redirect: indexw048l0l5VoMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexw048l0l5VoMeta?.name ?? "index___zh",
    path: indexw048l0l5VoMeta?.path ?? "/zh",
    meta: indexw048l0l5VoMeta || {},
    alias: indexw048l0l5VoMeta?.alias || [],
    redirect: indexw048l0l5VoMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: templatepsqyqBFYKSMeta?.name ?? "internal-fundReport-template___en",
    path: templatepsqyqBFYKSMeta?.path ?? "/en/internal/fundReport/template",
    meta: templatepsqyqBFYKSMeta || {},
    alias: templatepsqyqBFYKSMeta?.alias || [],
    redirect: templatepsqyqBFYKSMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/internal/fundReport/template.vue").then(m => m.default || m)
  },
  {
    name: templatepsqyqBFYKSMeta?.name ?? "internal-fundReport-template",
    path: templatepsqyqBFYKSMeta?.path ?? "/internal/fundReport/template",
    meta: templatepsqyqBFYKSMeta || {},
    alias: templatepsqyqBFYKSMeta?.alias || [],
    redirect: templatepsqyqBFYKSMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/internal/fundReport/template.vue").then(m => m.default || m)
  },
  {
    name: templatepsqyqBFYKSMeta?.name ?? "internal-fundReport-template___zh",
    path: templatepsqyqBFYKSMeta?.path ?? "/zh/internal/fundReport/template",
    meta: templatepsqyqBFYKSMeta || {},
    alias: templatepsqyqBFYKSMeta?.alias || [],
    redirect: templatepsqyqBFYKSMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/internal/fundReport/template.vue").then(m => m.default || m)
  },
  {
    name: _91_91type_93_93J8UhaDtAMeMeta?.name ?? "manage-type___en",
    path: _91_91type_93_93J8UhaDtAMeMeta?.path ?? "/en/manage/:type?",
    meta: _91_91type_93_93J8UhaDtAMeMeta || {},
    alias: _91_91type_93_93J8UhaDtAMeMeta?.alias || [],
    redirect: _91_91type_93_93J8UhaDtAMeMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/manage/[[type]].vue").then(m => m.default || m)
  },
  {
    name: _91_91type_93_93J8UhaDtAMeMeta?.name ?? "manage-type",
    path: _91_91type_93_93J8UhaDtAMeMeta?.path ?? "/manage/:type?",
    meta: _91_91type_93_93J8UhaDtAMeMeta || {},
    alias: _91_91type_93_93J8UhaDtAMeMeta?.alias || [],
    redirect: _91_91type_93_93J8UhaDtAMeMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/manage/[[type]].vue").then(m => m.default || m)
  },
  {
    name: _91_91type_93_93J8UhaDtAMeMeta?.name ?? "manage-type___zh",
    path: _91_91type_93_93J8UhaDtAMeMeta?.path ?? "/zh/manage/:type?",
    meta: _91_91type_93_93J8UhaDtAMeMeta || {},
    alias: _91_91type_93_93J8UhaDtAMeMeta?.alias || [],
    redirect: _91_91type_93_93J8UhaDtAMeMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/manage/[[type]].vue").then(m => m.default || m)
  },
  {
    name: meetingtAXtrDUHjyMeta?.name ?? "meeting___en",
    path: meetingtAXtrDUHjyMeta?.path ?? "/en/meeting",
    meta: meetingtAXtrDUHjyMeta || {},
    alias: meetingtAXtrDUHjyMeta?.alias || [],
    redirect: meetingtAXtrDUHjyMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/meeting.vue").then(m => m.default || m)
  },
  {
    name: meetingtAXtrDUHjyMeta?.name ?? "meeting",
    path: meetingtAXtrDUHjyMeta?.path ?? "/meeting",
    meta: meetingtAXtrDUHjyMeta || {},
    alias: meetingtAXtrDUHjyMeta?.alias || [],
    redirect: meetingtAXtrDUHjyMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/meeting.vue").then(m => m.default || m)
  },
  {
    name: meetingtAXtrDUHjyMeta?.name ?? "meeting___zh",
    path: meetingtAXtrDUHjyMeta?.path ?? "/zh/meeting",
    meta: meetingtAXtrDUHjyMeta || {},
    alias: meetingtAXtrDUHjyMeta?.alias || [],
    redirect: meetingtAXtrDUHjyMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/meeting.vue").then(m => m.default || m)
  },
  {
    name: _91token_934nx9suzrevMeta?.name ?? "member-forget-usercode-token___en",
    path: _91token_934nx9suzrevMeta?.path ?? "/en/member/forget/:usercode()/:token()",
    meta: _91token_934nx9suzrevMeta || {},
    alias: _91token_934nx9suzrevMeta?.alias || [],
    redirect: _91token_934nx9suzrevMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/forget/[usercode]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_934nx9suzrevMeta?.name ?? "member-forget-usercode-token",
    path: _91token_934nx9suzrevMeta?.path ?? "/member/forget/:usercode()/:token()",
    meta: _91token_934nx9suzrevMeta || {},
    alias: _91token_934nx9suzrevMeta?.alias || [],
    redirect: _91token_934nx9suzrevMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/forget/[usercode]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_934nx9suzrevMeta?.name ?? "member-forget-usercode-token___zh",
    path: _91token_934nx9suzrevMeta?.path ?? "/zh/member/forget/:usercode()/:token()",
    meta: _91token_934nx9suzrevMeta || {},
    alias: _91token_934nx9suzrevMeta?.alias || [],
    redirect: _91token_934nx9suzrevMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/forget/[usercode]/[token].vue").then(m => m.default || m)
  },
  {
    name: indexuvi73LQD2FMeta?.name ?? "member-forget___en",
    path: indexuvi73LQD2FMeta?.path ?? "/en/member/forget",
    meta: indexuvi73LQD2FMeta || {},
    alias: indexuvi73LQD2FMeta?.alias || [],
    redirect: indexuvi73LQD2FMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/forget/index.vue").then(m => m.default || m)
  },
  {
    name: indexuvi73LQD2FMeta?.name ?? "member-forget",
    path: indexuvi73LQD2FMeta?.path ?? "/member/forget",
    meta: indexuvi73LQD2FMeta || {},
    alias: indexuvi73LQD2FMeta?.alias || [],
    redirect: indexuvi73LQD2FMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/forget/index.vue").then(m => m.default || m)
  },
  {
    name: indexuvi73LQD2FMeta?.name ?? "member-forget___zh",
    path: indexuvi73LQD2FMeta?.path ?? "/zh/member/forget",
    meta: indexuvi73LQD2FMeta || {},
    alias: indexuvi73LQD2FMeta?.alias || [],
    redirect: indexuvi73LQD2FMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/forget/index.vue").then(m => m.default || m)
  },
  {
    name: _91otpCode_93AikG8LU3qAMeta?.name ?? "member-otp-signin-usercode-otpCode___en",
    path: _91otpCode_93AikG8LU3qAMeta?.path ?? "/en/member/otp-signin/:usercode()/:otpCode()",
    meta: _91otpCode_93AikG8LU3qAMeta || {},
    alias: _91otpCode_93AikG8LU3qAMeta?.alias || [],
    redirect: _91otpCode_93AikG8LU3qAMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/otp-signin/[usercode]/[otpCode].vue").then(m => m.default || m)
  },
  {
    name: _91otpCode_93AikG8LU3qAMeta?.name ?? "member-otp-signin-usercode-otpCode",
    path: _91otpCode_93AikG8LU3qAMeta?.path ?? "/member/otp-signin/:usercode()/:otpCode()",
    meta: _91otpCode_93AikG8LU3qAMeta || {},
    alias: _91otpCode_93AikG8LU3qAMeta?.alias || [],
    redirect: _91otpCode_93AikG8LU3qAMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/otp-signin/[usercode]/[otpCode].vue").then(m => m.default || m)
  },
  {
    name: _91otpCode_93AikG8LU3qAMeta?.name ?? "member-otp-signin-usercode-otpCode___zh",
    path: _91otpCode_93AikG8LU3qAMeta?.path ?? "/zh/member/otp-signin/:usercode()/:otpCode()",
    meta: _91otpCode_93AikG8LU3qAMeta || {},
    alias: _91otpCode_93AikG8LU3qAMeta?.alias || [],
    redirect: _91otpCode_93AikG8LU3qAMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/otp-signin/[usercode]/[otpCode].vue").then(m => m.default || m)
  },
  {
    name: passwd07uH0fygbUMeta?.name ?? "member-passwd___en",
    path: passwd07uH0fygbUMeta?.path ?? "/en/member/passwd",
    meta: passwd07uH0fygbUMeta || {},
    alias: passwd07uH0fygbUMeta?.alias || [],
    redirect: passwd07uH0fygbUMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/passwd.vue").then(m => m.default || m)
  },
  {
    name: passwd07uH0fygbUMeta?.name ?? "member-passwd",
    path: passwd07uH0fygbUMeta?.path ?? "/member/passwd",
    meta: passwd07uH0fygbUMeta || {},
    alias: passwd07uH0fygbUMeta?.alias || [],
    redirect: passwd07uH0fygbUMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/passwd.vue").then(m => m.default || m)
  },
  {
    name: passwd07uH0fygbUMeta?.name ?? "member-passwd___zh",
    path: passwd07uH0fygbUMeta?.path ?? "/zh/member/passwd",
    meta: passwd07uH0fygbUMeta || {},
    alias: passwd07uH0fygbUMeta?.alias || [],
    redirect: passwd07uH0fygbUMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/passwd.vue").then(m => m.default || m)
  },
  {
    name: signinWD9DmTfSyBMeta?.name ?? "member-signin___en",
    path: signinWD9DmTfSyBMeta?.path ?? "/en/member/signin",
    meta: signinWD9DmTfSyBMeta || {},
    alias: signinWD9DmTfSyBMeta?.alias || [],
    redirect: signinWD9DmTfSyBMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/signin.vue").then(m => m.default || m)
  },
  {
    name: signinWD9DmTfSyBMeta?.name ?? "member-signin",
    path: signinWD9DmTfSyBMeta?.path ?? "/member/signin",
    meta: signinWD9DmTfSyBMeta || {},
    alias: signinWD9DmTfSyBMeta?.alias || [],
    redirect: signinWD9DmTfSyBMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/signin.vue").then(m => m.default || m)
  },
  {
    name: signinWD9DmTfSyBMeta?.name ?? "member-signin___zh",
    path: signinWD9DmTfSyBMeta?.path ?? "/zh/member/signin",
    meta: signinWD9DmTfSyBMeta || {},
    alias: signinWD9DmTfSyBMeta?.alias || [],
    redirect: signinWD9DmTfSyBMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/signin.vue").then(m => m.default || m)
  },
  {
    name: signuplY0u67vh8KMeta?.name ?? "member-signup___en",
    path: signuplY0u67vh8KMeta?.path ?? "/en/member/signup",
    meta: signuplY0u67vh8KMeta || {},
    alias: signuplY0u67vh8KMeta?.alias || [],
    redirect: signuplY0u67vh8KMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/signup.vue").then(m => m.default || m)
  },
  {
    name: signuplY0u67vh8KMeta?.name ?? "member-signup",
    path: signuplY0u67vh8KMeta?.path ?? "/member/signup",
    meta: signuplY0u67vh8KMeta || {},
    alias: signuplY0u67vh8KMeta?.alias || [],
    redirect: signuplY0u67vh8KMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/signup.vue").then(m => m.default || m)
  },
  {
    name: signuplY0u67vh8KMeta?.name ?? "member-signup___zh",
    path: signuplY0u67vh8KMeta?.path ?? "/zh/member/signup",
    meta: signuplY0u67vh8KMeta || {},
    alias: signuplY0u67vh8KMeta?.alias || [],
    redirect: signuplY0u67vh8KMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/member/signup.vue").then(m => m.default || m)
  },
  {
    name: assetlpiDOQ9TaAMeta?.name ?? "my-asset___en",
    path: assetlpiDOQ9TaAMeta?.path ?? "/en/my/asset",
    meta: assetlpiDOQ9TaAMeta || {},
    alias: assetlpiDOQ9TaAMeta?.alias || [],
    redirect: assetlpiDOQ9TaAMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/asset.vue").then(m => m.default || m)
  },
  {
    name: assetlpiDOQ9TaAMeta?.name ?? "my-asset",
    path: assetlpiDOQ9TaAMeta?.path ?? "/my/asset",
    meta: assetlpiDOQ9TaAMeta || {},
    alias: assetlpiDOQ9TaAMeta?.alias || [],
    redirect: assetlpiDOQ9TaAMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/asset.vue").then(m => m.default || m)
  },
  {
    name: assetlpiDOQ9TaAMeta?.name ?? "my-asset___zh",
    path: assetlpiDOQ9TaAMeta?.path ?? "/zh/my/asset",
    meta: assetlpiDOQ9TaAMeta || {},
    alias: assetlpiDOQ9TaAMeta?.alias || [],
    redirect: assetlpiDOQ9TaAMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/asset.vue").then(m => m.default || m)
  },
  {
    name: chargeawCy48nGOkMeta?.name ?? "my-charge___en",
    path: chargeawCy48nGOkMeta?.path ?? "/en/my/charge",
    meta: chargeawCy48nGOkMeta || {},
    alias: chargeawCy48nGOkMeta?.alias || [],
    redirect: chargeawCy48nGOkMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/charge.vue").then(m => m.default || m)
  },
  {
    name: chargeawCy48nGOkMeta?.name ?? "my-charge",
    path: chargeawCy48nGOkMeta?.path ?? "/my/charge",
    meta: chargeawCy48nGOkMeta || {},
    alias: chargeawCy48nGOkMeta?.alias || [],
    redirect: chargeawCy48nGOkMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/charge.vue").then(m => m.default || m)
  },
  {
    name: chargeawCy48nGOkMeta?.name ?? "my-charge___zh",
    path: chargeawCy48nGOkMeta?.path ?? "/zh/my/charge",
    meta: chargeawCy48nGOkMeta || {},
    alias: chargeawCy48nGOkMeta?.alias || [],
    redirect: chargeawCy48nGOkMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/charge.vue").then(m => m.default || m)
  },
  {
    name: clientsnhKGAlW0JpMeta?.name ?? "my-clients___en",
    path: clientsnhKGAlW0JpMeta?.path ?? "/en/my/clients",
    meta: clientsnhKGAlW0JpMeta || {},
    alias: clientsnhKGAlW0JpMeta?.alias || [],
    redirect: clientsnhKGAlW0JpMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/clients.vue").then(m => m.default || m)
  },
  {
    name: clientsnhKGAlW0JpMeta?.name ?? "my-clients",
    path: clientsnhKGAlW0JpMeta?.path ?? "/my/clients",
    meta: clientsnhKGAlW0JpMeta || {},
    alias: clientsnhKGAlW0JpMeta?.alias || [],
    redirect: clientsnhKGAlW0JpMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/clients.vue").then(m => m.default || m)
  },
  {
    name: clientsnhKGAlW0JpMeta?.name ?? "my-clients___zh",
    path: clientsnhKGAlW0JpMeta?.path ?? "/zh/my/clients",
    meta: clientsnhKGAlW0JpMeta || {},
    alias: clientsnhKGAlW0JpMeta?.alias || [],
    redirect: clientsnhKGAlW0JpMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/clients.vue").then(m => m.default || m)
  },
  {
    name: _91_91type_93_93yRPg6Ek1CiMeta?.name ?? "my-dealing-type___en",
    path: _91_91type_93_93yRPg6Ek1CiMeta?.path ?? "/en/my/dealing/:type?",
    meta: _91_91type_93_93yRPg6Ek1CiMeta || {},
    alias: _91_91type_93_93yRPg6Ek1CiMeta?.alias || [],
    redirect: _91_91type_93_93yRPg6Ek1CiMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/dealing/[[type]].vue").then(m => m.default || m)
  },
  {
    name: _91_91type_93_93yRPg6Ek1CiMeta?.name ?? "my-dealing-type",
    path: _91_91type_93_93yRPg6Ek1CiMeta?.path ?? "/my/dealing/:type?",
    meta: _91_91type_93_93yRPg6Ek1CiMeta || {},
    alias: _91_91type_93_93yRPg6Ek1CiMeta?.alias || [],
    redirect: _91_91type_93_93yRPg6Ek1CiMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/dealing/[[type]].vue").then(m => m.default || m)
  },
  {
    name: _91_91type_93_93yRPg6Ek1CiMeta?.name ?? "my-dealing-type___zh",
    path: _91_91type_93_93yRPg6Ek1CiMeta?.path ?? "/zh/my/dealing/:type?",
    meta: _91_91type_93_93yRPg6Ek1CiMeta || {},
    alias: _91_91type_93_93yRPg6Ek1CiMeta?.alias || [],
    redirect: _91_91type_93_93yRPg6Ek1CiMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/dealing/[[type]].vue").then(m => m.default || m)
  },
  {
    name: historyETLBzcGhCnMeta?.name ?? "my-history___en",
    path: historyETLBzcGhCnMeta?.path ?? "/en/my/history",
    meta: historyETLBzcGhCnMeta || {},
    alias: historyETLBzcGhCnMeta?.alias || [],
    redirect: historyETLBzcGhCnMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/history.vue").then(m => m.default || m)
  },
  {
    name: historyETLBzcGhCnMeta?.name ?? "my-history",
    path: historyETLBzcGhCnMeta?.path ?? "/my/history",
    meta: historyETLBzcGhCnMeta || {},
    alias: historyETLBzcGhCnMeta?.alias || [],
    redirect: historyETLBzcGhCnMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/history.vue").then(m => m.default || m)
  },
  {
    name: historyETLBzcGhCnMeta?.name ?? "my-history___zh",
    path: historyETLBzcGhCnMeta?.path ?? "/zh/my/history",
    meta: historyETLBzcGhCnMeta || {},
    alias: historyETLBzcGhCnMeta?.alias || [],
    redirect: historyETLBzcGhCnMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/history.vue").then(m => m.default || m)
  },
  {
    name: notify_45_91id_93AD3045aJaDMeta?.name ?? "my-notify-id___en",
    path: notify_45_91id_93AD3045aJaDMeta?.path ?? "/en/my/notify-:id()",
    meta: notify_45_91id_93AD3045aJaDMeta || {},
    alias: notify_45_91id_93AD3045aJaDMeta?.alias || [],
    redirect: notify_45_91id_93AD3045aJaDMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/notify-[id].vue").then(m => m.default || m)
  },
  {
    name: notify_45_91id_93AD3045aJaDMeta?.name ?? "my-notify-id",
    path: notify_45_91id_93AD3045aJaDMeta?.path ?? "/my/notify-:id()",
    meta: notify_45_91id_93AD3045aJaDMeta || {},
    alias: notify_45_91id_93AD3045aJaDMeta?.alias || [],
    redirect: notify_45_91id_93AD3045aJaDMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/notify-[id].vue").then(m => m.default || m)
  },
  {
    name: notify_45_91id_93AD3045aJaDMeta?.name ?? "my-notify-id___zh",
    path: notify_45_91id_93AD3045aJaDMeta?.path ?? "/zh/my/notify-:id()",
    meta: notify_45_91id_93AD3045aJaDMeta || {},
    alias: notify_45_91id_93AD3045aJaDMeta?.alias || [],
    redirect: notify_45_91id_93AD3045aJaDMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/notify-[id].vue").then(m => m.default || m)
  },
  {
    name: _91_91tab_93_93aOz5qxVN9NMeta?.name ?? "my-notify-tab___en",
    path: _91_91tab_93_93aOz5qxVN9NMeta?.path ?? "/en/my/notify/:tab?",
    meta: _91_91tab_93_93aOz5qxVN9NMeta || {},
    alias: _91_91tab_93_93aOz5qxVN9NMeta?.alias || [],
    redirect: _91_91tab_93_93aOz5qxVN9NMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/notify/[[tab]].vue").then(m => m.default || m)
  },
  {
    name: _91_91tab_93_93aOz5qxVN9NMeta?.name ?? "my-notify-tab",
    path: _91_91tab_93_93aOz5qxVN9NMeta?.path ?? "/my/notify/:tab?",
    meta: _91_91tab_93_93aOz5qxVN9NMeta || {},
    alias: _91_91tab_93_93aOz5qxVN9NMeta?.alias || [],
    redirect: _91_91tab_93_93aOz5qxVN9NMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/notify/[[tab]].vue").then(m => m.default || m)
  },
  {
    name: _91_91tab_93_93aOz5qxVN9NMeta?.name ?? "my-notify-tab___zh",
    path: _91_91tab_93_93aOz5qxVN9NMeta?.path ?? "/zh/my/notify/:tab?",
    meta: _91_91tab_93_93aOz5qxVN9NMeta || {},
    alias: _91_91tab_93_93aOz5qxVN9NMeta?.alias || [],
    redirect: _91_91tab_93_93aOz5qxVN9NMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/my/notify/[[tab]].vue").then(m => m.default || m)
  },
  {
    name: newsxjynT9mNoaMeta?.name ?? "news___en",
    path: newsxjynT9mNoaMeta?.path ?? "/en/news",
    meta: newsxjynT9mNoaMeta || {},
    alias: newsxjynT9mNoaMeta?.alias || [],
    redirect: newsxjynT9mNoaMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/news.vue").then(m => m.default || m)
  },
  {
    name: newsxjynT9mNoaMeta?.name ?? "news",
    path: newsxjynT9mNoaMeta?.path ?? "/news",
    meta: newsxjynT9mNoaMeta || {},
    alias: newsxjynT9mNoaMeta?.alias || [],
    redirect: newsxjynT9mNoaMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/news.vue").then(m => m.default || m)
  },
  {
    name: newsxjynT9mNoaMeta?.name ?? "news___zh",
    path: newsxjynT9mNoaMeta?.path ?? "/zh/news",
    meta: newsxjynT9mNoaMeta || {},
    alias: newsxjynT9mNoaMeta?.alias || [],
    redirect: newsxjynT9mNoaMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/news.vue").then(m => m.default || m)
  },
  {
    name: recruita9ILAneq1ZMeta?.name ?? "recruit___en",
    path: recruita9ILAneq1ZMeta?.path ?? "/en/recruit",
    meta: recruita9ILAneq1ZMeta || {},
    alias: recruita9ILAneq1ZMeta?.alias || [],
    redirect: recruita9ILAneq1ZMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/recruit.vue").then(m => m.default || m)
  },
  {
    name: recruita9ILAneq1ZMeta?.name ?? "recruit",
    path: recruita9ILAneq1ZMeta?.path ?? "/recruit",
    meta: recruita9ILAneq1ZMeta || {},
    alias: recruita9ILAneq1ZMeta?.alias || [],
    redirect: recruita9ILAneq1ZMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/recruit.vue").then(m => m.default || m)
  },
  {
    name: recruita9ILAneq1ZMeta?.name ?? "recruit___zh",
    path: recruita9ILAneq1ZMeta?.path ?? "/zh/recruit",
    meta: recruita9ILAneq1ZMeta || {},
    alias: recruita9ILAneq1ZMeta?.alias || [],
    redirect: recruita9ILAneq1ZMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/recruit.vue").then(m => m.default || m)
  },
  {
    name: _91_91tab_93_938Gf8csMQcZMeta?.name ?? "sub-accounts-tab___en",
    path: _91_91tab_93_938Gf8csMQcZMeta?.path ?? "/en/sub-accounts/:tab?",
    meta: _91_91tab_93_938Gf8csMQcZMeta || {},
    alias: _91_91tab_93_938Gf8csMQcZMeta?.alias || [],
    redirect: _91_91tab_93_938Gf8csMQcZMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/sub-accounts/[[tab]].vue").then(m => m.default || m)
  },
  {
    name: _91_91tab_93_938Gf8csMQcZMeta?.name ?? "sub-accounts-tab",
    path: _91_91tab_93_938Gf8csMQcZMeta?.path ?? "/sub-accounts/:tab?",
    meta: _91_91tab_93_938Gf8csMQcZMeta || {},
    alias: _91_91tab_93_938Gf8csMQcZMeta?.alias || [],
    redirect: _91_91tab_93_938Gf8csMQcZMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/sub-accounts/[[tab]].vue").then(m => m.default || m)
  },
  {
    name: _91_91tab_93_938Gf8csMQcZMeta?.name ?? "sub-accounts-tab___zh",
    path: _91_91tab_93_938Gf8csMQcZMeta?.path ?? "/zh/sub-accounts/:tab?",
    meta: _91_91tab_93_938Gf8csMQcZMeta || {},
    alias: _91_91tab_93_938Gf8csMQcZMeta?.alias || [],
    redirect: _91_91tab_93_938Gf8csMQcZMeta?.redirect || undefined,
    component: () => import("/home/ugadmin/UGFundWebSite/frontend/pages/sub-accounts/[[tab]].vue").then(m => m.default || m)
  }
]