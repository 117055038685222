<!-- TODO 自訂錯誤頁面 -->
<template>
  <div class="layout">
    <div class="wrapper">
      <div class="landing-page">
        <div style="text-align:center;" class="icon__download">
          <img src="~assets/images/ug-logo.png" alt="">
        </div>
        <h1> {{ error.statusCode }} </h1>
        <p> {{ error.message }}</p>
        <button @click="handleError">{{ $t(Words.BackHome) }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
const localePath = useLocalePath()

const props = defineProps({
  error: Object
})

if (props.error && props.error.statusCode == 404) {
  setPageMeta({
    zh_title: '找不到頁面',
    en_title: 'Not Found',
  })
}

if (props.error && props.error.statusCode == 500) {
  setPageMeta({
    zh_title: '系統發生錯誤',
    en_title: 'Error',
  })
}

const handleError = () => clearError({ redirect: localePath('/') })
</script>

<style lang="stylus" scoped>
.layout * {
  font-family: sans-serif;
}
.layout {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 0px 30px;
  background: $gray-lighten-3;
  color: $gray-darken-4;
}

.wrapper {
  max-width: 960px;
  width: 100%; 
  margin: 30px auto;
}
.landing-page {
  max-width: 960px;
  height: 475px;
  margin: 0;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
svg {
  width: 50%;
  max-height: 225px;
  height: auto;
  margin: 0 0 15px;
}
h1 {
  font-size: 52px;
  margin: 0;
}
p {
  font-size: 24px;
  width: 60%; 
  margin: 16px auto 24px;
  text-align: center;
}
button {
  border-radius: 50px;
  padding: 8px 24px;
  font-size: 16px;
  cursor: pointer;
  background: $primary;
  color: #fff;
  border: none;
  box-shadow: 0 4px 8px 0 #ccc;
}
</style>