import * as Sentry from '@sentry/vue'
import { IMember, ISession } from '@/api/members/types'

/**
 * 存取會員資料
 */
export const useMember = () => {

  const member = useState<IMember | null>('currentMember', () => ref(null))
  const gtag = useGtag()

  // GA: 更新 user_id
  watchEffect(() => {
    if (member.value) {
      gtag('set', {
        'user_id': member.value.client_id
      })
    } else {
      gtag('set', {
        'user_id': null
      })
    }
  })

  // Sentry
  // SSR: Sentry.setUser is not a function
  if (Sentry.setUser) {
    watchEffect(() => {
      if (member.value) {
        Sentry.setUser({
          id: member.value.id,
          email: member.value.email,
          username: member.value.name,
        })
      }
    })
  }

  return member
}
