import { trackEvent } from "@/api/track"
import { ComputedRef, Ref, nextTick } from "vue"
import { useInterval, useTimeoutFn, UseIntervalControls, Pausable, Stoppable } from '@vueuse/core'
import { CookieRef } from "nuxt/dist/app/composables"

type EventArguments = {
  /** 用來分辨該事件的標的，例如 `/zh/awards` */
  page_location?: string
  /** 頁面標題或事件主題 */
  page_title?: string
}

/**
 * 事件追蹤器
 * 模仿 GA4，紀錄使用者瀏覽進程
 */
class EventTracker {

  sampleRate = 100 // 取樣時間
  pvThrottle = 5 * 1000 // PV 送出延遲
  activeThrottle = 10 * 1000 // 活動有效時間
  engagementTimeThrottle = 1 * 1000 // 最小互動時間，低於這個值就不送出
  scrollThrottle = 0.9 // 捲動到多少比例時送出事件
  timerDelay = 2 * 1000 // 計時器再次重新啟動的延遲

  sessionIdStore: CookieRef<string>
  member: ReturnType<typeof useMember>

  timer: UseIntervalControls & Pausable // 計時器
  interactiveTimeout?: Stoppable // 互動閒置倒數計時器
  pvTimeout?: ReturnType<typeof setTimeout> // PV 延遲計時器

  engagementTime: ComputedRef<number> // 參與時間
  isPVSent = false // 目前頁面已觸發PV事件
  isPageScrollSent = false // 目前頁面已觸發捲動事件

  constructor() {

    this.sessionIdStore = useCookie('ug_tracker_session')

    this.member = useMember()
    this.timer = useInterval(this.sampleRate, { controls: true, immediate: false })
    this.engagementTime = computed(() => this.timer.counter.value * this.sampleRate)

    if (process.client) {

      // 偵測到閒置暫停計時
      this.interactiveTimeout = useTimeoutFn(() => {
        this.timer.pause()
      }, this.activeThrottle)

      // 送出初始 PV
      this.resetPageStatus()
      this.sendPageView(true)

      // 監測換頁
      const router = useRouter()
      router.beforeEach((to, from, next) => {
        if (to.fullPath !== from.fullPath) {
        }
        next()
      })
      // 換頁前結算互動時間
      router.beforeResolve(to => {
        this.sendEngagement(true)
      })
      // 換頁後送出新的 PV
      router.afterEach((to, from, failure) => {
        if (!failure && (to.fullPath !== from.fullPath)) {
          nextTick(() => {
            this.resetPageStatus()
            this.sendPageView()
          })
        }
      })

      // 離開頁面時結算互動時間
      window.addEventListener('beforeunload', () => this.sendEngagement(true))

      // 互動偵測
      window.addEventListener('mousemove', () => this.setActive())
      window.addEventListener('touchstart', () => this.setActive())
      window.addEventListener('scroll', () => this.setActive())

      // 視窗閒置與繼續
      window.addEventListener('focusin', () => this.timer.resume())
      window.addEventListener('blur', () => this.timer.pause())

      // 偵測捲動
      window.addEventListener('scroll', () => this.detectScroll())

    }
  }

  get sessionId(): string | undefined {
    return this.sessionIdStore.value || undefined
  }

  set sessionId(id: string) {
    this.sessionIdStore.value = id
  }

  detectScroll() {
    const progress = document.documentElement.scrollTop / (window.document.body.scrollHeight - window.innerHeight)
    if (progress >= this.scrollThrottle) {
      this.sendScrollEvent()
    }
  }

  // 設定為互動中
  setActive() {
    if (!this.timer.isActive.value) {
      this.timer.resume()
    }
    this.interactiveTimeout?.start()
  }

  // 送出 PV
  sendPageView(immediate = false) {
    if (this.isPVSent) return
    clearTimeout(this.pvTimeout)
    this.pvTimeout = setTimeout(() => {
      this.sendEvent('page_view')
      this.isPVSent = true
      this.timer.pause()
      this.timer.reset()
      setTimeout(() => this.timer.resume(), this.timerDelay) // 延遲重新計時
    }, immediate ? 0 : this.pvThrottle)
  }

  // 送出捲動事件 (已讀)
  sendScrollEvent() {
    if (!this.isPVSent) return
    if (this.isPageScrollSent) return
    this.sendEvent('scroll')
    this.isPageScrollSent = true
  }

  // 結算送出參與時間
  sendEngagement(stop = false) {
    if (stop) {
      this.timer.pause()
    }
    if (this.engagementTime.value >= this.engagementTimeThrottle) {
      this.sendEvent('user_engagement')
    }
  }

  // 重設頁面狀態
  resetPageStatus() {
    this.isPVSent = false
    this.isPageScrollSent = false
  }

  // 送出事件
  sendEvent(eventName: string, eventArgs?: EventArguments) {
    if (process.client) {
      const params = {
        en: eventName,
        sid: this.sessionId,
        uid: this.member.value?.id,
      }
      if (eventArgs) {
        Object.assign(params, {
          dt: eventArgs.page_title ?? window.document.title,
          dl: eventArgs.page_location ?? window.location.href,
          et: undefined,
        })
      } else {
        const et = this.flushEngagementTime() // 結算時間
        Object.assign(params, {
          dt: window.document.title,
          dl: window.location.href,
          et: et,
        })
      }
      const easterEgg = useEasterEgg('test-event-tracker')
      if (process.env.NODE_ENV === 'development' || easterEgg.activated.value) {
        console.groupCollapsed(`EventTracker: sendEvent('${eventName}')`)
        console.table(params)
        console.groupEnd()
      }
      trackEvent(params).then(({ data }) => {
        if (data.value) {
          // 紀錄目前 session ID
          this.sessionId = data.value.session_id
        }
      })
    }
  }

  // 結算時間 
  flushEngagementTime(): number {
    const time = this.engagementTime.value
    this.timer.reset()
    if (time >= this.engagementTimeThrottle) {
      return time
    }
    return 0
  }

  /** 發送簡單的點擊紀錄 */
  sendClick(title: string, location: string){
    this.sendEvent('click', {
      page_title: title,
      page_location: location,
    })
    const easterEgg = useEasterEgg('test-event-tracker')
    if (process.env.NODE_ENV === 'development' || easterEgg.activated.value) {
      console.log(`EventTracker: sendClick('${location},${title}')`)
    }
  }

}

Object.defineProperty(EventTracker.prototype, 'sessionId', { enumerable: true })
Object.defineProperty(EventTracker.prototype, 'engagementTime', { writable: true, enumerable: true })

let tracker: EventTracker

export default function useEventTracker() {

  if (!tracker) {
    tracker = new EventTracker()
  }

  return tracker

}