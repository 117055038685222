/**
 * 將列舉項目自動暴露到全域
 */
export { Words } from '@/lang/types'
export { Privilege } from '@/lib/privilege'
export { TradeStatus } from '@/api/dealings/TradeStatus'
export { AlertIcon } from '@/lib/alert'
export { MemberHolderType, MemberGroup } from '@/api/members/types'
export { FundID, FundName } from '@/api/funds/enum'

import { Privilege } from '@/lib/privilege'
import { MemberGroup } from '@/api/members/types'

export enum TradeType {
  'SBS' = '認購',
  'RDM' = '贖回',
  'SWITCH' = '轉換',
}

export function isPrivilege(value: any): value is Privilege {
  return Object.values(Privilege).includes(value);
}

export function isMemberGroup(value: any): value is MemberGroup {
  return Object.values(MemberGroup).includes(value);
}