import * as Sentry from '@sentry/vue'
import { Router } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { vueApp } = nuxtApp
  const vueRouter = nuxtApp.$router as Router

  Sentry.init({
    app: vueApp,
    dsn: "https://e15bb21e08234965b6155f43d2eff7e5@o104085.ingest.sentry.io/4505390966767616",
    enabled: config.public.sentryEnabled,
    environment: config.public.sentryEnv,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "www.ugfunds.com",
          "v3.ugfunds.com",
          "test.ugfunds.com",
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(vueRouter),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

})