import { defineStore } from 'pinia'
import { IMember, ISession } from '@/api/members/types'

/**
 * Session Store 儲存了目前會員登入狀態，使瀏覽器視窗同步
 */
export const useSessionStore = defineStore('session', () => {
  const member = useState<IMember | null>('currentMember', () => ref(null))
  const authorization = useAuthorization()
  const session = useState<ISession | null>('currentSession', () => ref(null))
  const openedTabs = ref(0)

  function setMember(value: IMember | null) {
    member.value = value
  }

  function setAuthorization(value: string | null) {
    authorization.value = value
  }

  function setSession(value: ISession | null) {
    session.value = value
  }

  function hookMounted(){
    openedTabs.value++
    if(process.client && window){
      window.addEventListener('beforeunload', (event) => {
        return hookUnmounted()
      }, { capture: true })
    }
  }

  function hookUnmounted(){
    openedTabs.value--
    if(openedTabs.value <= 0 && member.value){
      useLogout()
      return false
    }
  }

  return {
    member,
    session,
    authorization,
    openedTabs,
    setMember,
    setAuthorization,
    setSession,
    hookMounted,
    hookUnmounted,
  }
})