import type { Privilege, MemberGroup } from '@/utils/enum'
/**
 * 在頁面檔案中定義權限，例如需要登入：
 * 
 * definePageMeta({
 *   requirePermissions: true,
 * })
 * 
 * 在頁面檔案中定義權限，例如需要登入，且具有 foo 或 bar 權限：
 * 
 * definePageMeta({
 *   requirePermissions: ['foo', 'bar'],
 * })
 * 
 */
export default defineNuxtPlugin((nuxtApp) => {

  const router = useRouter()
  let authLock = false // 鎖定正在進行登入流程

  // 紀錄目前瀏覽頁面的所需權限
  const currentRequiredPermissions = usePagePermission()

  // 進入每個頁面時，檢查會員權限
  router.beforeEach((to, from, next) => {

    authLock = true

    const requirePermissions = to.matched[0]?.meta.requirePermissions as string[] | undefined
    currentRequiredPermissions.value = requirePermissions

    useMemberAsync({
      require: currentRequiredPermissions.value as any,
      navigationGuard: {
        to, next
      },
    }).finally(() => {
      authLock = false
    })

  })

  // 進入頁面前，檢查會員的語系瀏覽權限，如果沒有，就跳到許可的語言
  router.beforeEach((to, from, next) => {
    const member = useMember()
    const { locale } = useNuxtApp().$i18n
    const currentLocale = locale.value as 'zh' | 'en'
    if (member.value && !member.value.locales.includes(currentLocale)) {
      const availableLocale = member.value.locales[0]
      next(changeRouteToLang(to.fullPath, availableLocale))
    }
    next()
  })

  router.isReady().then(() => {
    // 隨時檢查所需權限與目前會員的匹配
    watchEffect(() => {
      if (currentRequiredPermissions.value && !useMember().value && !authLock) {
        useMemberAsync({
          require: currentRequiredPermissions.value as any
        })
      }
    })
  })

})

// 要有更聰明的方法來轉換
function changeRouteToLang(routePath: string, lang: 'zh' | 'en') {
  return routePath.replace(/^\/?(zh|en)/, `/${lang}`)
}