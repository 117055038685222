import { IMember, ISession } from '@/api/members/types'
import { memberAuthorization } from '@/api/members'
import { useSessionStore } from '@/stores/sessionStore'
export { useSessionStore }
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { Privilege } from '@/lib/privilege'
import { MemberGroup } from '@/api/members/types'

interface useMemberOptions {
  /**
   * 若指定 true 則必須登入
   * 若指定 string[] 則檢查是否擁有符合的權限 (TODO)
   */
  require?: boolean | (Privilege | MemberGroup | 'member')[]
  navigationGuard?: {
    to: RouteLocationNormalized,
    next: NavigationGuardNext,
  }
}

/**
 * 存取會員資料
 */
export const useMemberAsync = ({ require, navigationGuard }: useMemberOptions = {}) => new Promise<IMember | null>((resolve) => {
  // 啟動 Pinia store 來共用 member
  useSessionStore()

  const { t } = useNuxtApp().$i18n

  const authorization = useAuthorization()
  const session = useState<ISession | null>('currentSession', () => ref(null))
  const member = useMember()

  function requireSignin() {
    if (require && !fitPrivilege()) {
      if (member.value) useToast(t(Words.ErrorAccountInvalid)) // TODO 登入畫面應該要重設
      useLogin({ navigationGuard })
      resolve(null)
    } else {
      navigationGuard && navigationGuard.next()
      resolve(member.value)
    }
  }

  // 檢查會員必須滿足權限
  function fitPrivilege(): boolean {
    const privileges = Object.values(Privilege)
    if (require instanceof Array) {
      if (!member.value) return false
      return require.some(condition => {
        if (condition === 'member') return !!member.value
        if (member.value && isPrivilege(condition)) {
          return ((member.value.privileges || []).findIndex(pv => pv === condition) != -1)
        }
        if (member.value && isMemberGroup(condition)) {
          return ((member.value.group || []).indexOf(condition) != -1)
        }
        return false
      })
    } else {
      return !!member.value
    }
  }

  // 使用 Token 驗證目前的身份
  if (authorization.value && !member.value) {
    memberAuthorization()
      .then(({ data, error }) => {
        if (data.value) {
          member.value = data.value.member
          session.value = data.value.session
        }
        if (error.value) {
          // TODO 無法驗證身份
          authorization.value = null
          useToast(t(Words.ErrorOnSessionExpired), { timeout: 5000 })
        }
      })
      .finally(() => {
        // 無法驗證身份，請重新登入
        requireSignin()
      })
  } else {
    requireSignin()
  }

  return member
})


