import { PiniaSharedState } from 'pinia-shared-state';

// https://github.com/wobsoriano/pinia-shared-state
export default defineNuxtPlugin((nuxtApp) => {
  const pinia = usePinia()

  // 只有在瀏覽器端 CSR 執行，忽略 SSR
  if (process.client) {
    pinia.use(
      PiniaSharedState({
        enable: true,
      }),
    )
  }

})
