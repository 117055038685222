import { joinURL } from 'ufo'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();

  const chunkErrors = new Set();

  router.beforeEach(() => {
    chunkErrors.clear();
  });

  nuxtApp.hook("app:chunkError", ({ error }) => {
    console.error("[app:chunkError]", error);
    chunkErrors.add(error);
    if ((error as TypeError).message.includes('Failed to fetch dynamically imported module')) {
      reloadNuxtApp({ force: true, persistState: true });
    }
  });

  /**
   * 不會被觸發
   * > reference: https://nuxt.com/docs/getting-started/error-handling#errors-downloading-js-chunks
   */
  router.onError((error, to) => {
    if (chunkErrors.has(error)) {
      const isHash = 'href' in to && (to.href as string).startsWith('#')
      const path = isHash ? config.app.baseURL + (to as any).href : joinURL(config.app.baseURL, to.fullPath)
      reloadNuxtApp({ path, persistState: true })
    }
  })

});
