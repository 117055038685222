// import Swiper core and required modules

/** 不同功能各自拆了模組，需要時必須各別 import(功能及css) */
// https://v8.swiperjs.com/vue
import SwiperCore, { Autoplay, Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Autoplay, Navigation, Pagination, A11y])

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('Swiper', Swiper);
  nuxtApp.vueApp.component('SwiperSlide', SwiperSlide);
})