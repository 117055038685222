import type { FetchError } from 'ofetch';
import { useAuthorization } from '@/composables/member/useAuthorization'
import { AsyncData } from 'nuxt/dist/app/composables/asyncData';
import { UseFetchOptions } from "nuxt/dist/app/composables/fetch";
import { hash } from 'ohash'


export type RequestMethod = "POST" | "GET" | "PUT" | "DELETE";
export type APIActionOptios<T = any> = Omit<
  UseFetchOptions<T>,
  "method" | "ignoreResponseError" | "onResponseError" | "watch"
>;


const mockups: {
  [key: string]: Function
} = {}

/**
 * 定義測試用的 API 假資料回應邏輯
 */
export function createMockup<
  TResponse extends Record<string, any>,
  TRequest extends Record<string, any> = Record<string, any>,
>(method: string, url: string, handler: (payload: TRequest, headers: Record<string, any>) => TResponse | Promise<TResponse>) {
  touchMockup(method, url, handler)
}

function touchMockup(method: string, url: string, handler?: Function) {
  const key = `${method}:${url}`
  if (handler) {
    mockups[key] = handler
  } else {
    return mockups[key]
  }
}

/**
 * 定義一個 API 請求的方法
 * @example
*  loadData = createAction<TResponse, TRequest, TError>(url)
*  loadData({ query: 'foo' }).then(({ data, error }) => {
*    if (error.value) {
*      console.error(error.value.statusCode, error.value.message, toRaw(error.value.data))
*    }
*    else if (data.value) console.error(data.value)
*  })
* @template TResponse 回應的資料型別
* @template TRequest 請求的資料型別
* @template TError
* @param url endponint
* @param defaultOptions ofetch Request Options
* @returns API action
*/
export function createAction<
  TResponse extends Record<string, any>,
  TRequest extends Record<string, any> = Record<string, any>,
  TError = any
>(
  method: RequestMethod,
  url: string,
  defaultOptions: APIActionOptios<TResponse> = {}
) {
  return function anonymousAction(
    payload?: TRequest,
    appendOptions: APIActionOptios<TResponse> = {}
  ) {
    const config = useRuntimeConfig();
    const authToken = useAuthorization();

    const baseURL = process.client
      ? window.location.origin
      : config.public.baseUrl;

    const { headers: _defaultHeaders, ...validDefaultOptions } = defaultOptions;
    const { headers: _appendHeaders, ...validAppendOptions } = appendOptions;

    const defaultHeaders = handleHeaders(toValue(defaultOptions.headers));
    const appendHeaders = handleHeaders(toValue(appendOptions.headers));

    const headers: Record<string, string> = {
      ...defaultHeaders,
      ...appendHeaders,
    };

    if (authToken.value) {
      // Only Allow `appendHeaders["Authorization"]` to override `authToken`
      headers["Authorization"] =
        appendHeaders["Authorization"] || `Bearer ${authToken.value}`;
    }

    const mockupHandler = touchMockup(method, url);
    const finalUrl = compileUrl(url, payload);
    const cacheKey = hash({
      method,
      url: finalUrl,
      payload: method === "GET" ? payload : {},
      key: validAppendOptions.key,
    })
    

    return useFetch(finalUrl, {
      ...validDefaultOptions,
      /**
       * https://github.com/unjs/ofetch#%EF%B8%8F-handling-errors
       */
      ignoreResponseError: true,
      onResponse: ({ request, response, options }) => {
        if (response.status === 200) {
          // 回應正確
          return response._data;
        } else {
          throw {
            message: response._data?.message
              ? response._data.message
              : response._data.error ??
              `發生不明的錯誤，狀態碼 ${response.status}`,
            status: response.status,
            statusCode: response.status,
            data: response._data,
          };
        }
      },
      onRequestError({ request, options, error }) {
        if (error.name === 'AbortError') {
          throw {
            message: "中斷請求",
            status: -500,
            statusCode: -500,
            error,
          };
        }
        throw {
          message: "網路連線錯誤",
          status: 0,
          statusCode: 0,
          error,
        };
      },
      onRequest: async (context) => {
        if (mockupHandler) {
          let mockupResult: any
          context.request = '/echo'
          context.options.method = 'POST'

          try {
            mockupResult = mockupHandler(payload, headers)
          } catch (error: any) {
            mockupResult = { error }
          }

          if (mockupResult instanceof Promise) {
            await mockupHandler(payload, headers).then((result: any) => {
              context.options.body = result
            }).catch((error: any) => {
              context.options.body = JSON.stringify({ error })
            })
          } else {
            context.options.body = mockupResult
          }
        }
      },
      ...validDefaultOptions,
      ...validAppendOptions,
      // Not Allowed to override
      method,
      [method === "GET" ? "params" : "body"]: payload,
      headers, // 傳遞 token
      baseURL,
      key: cacheKey,
      /**
       * Same as `old.useFetchAdapter`
       * TODO: expose parameter
       */
      watch: false,
    }) as AsyncData<TResponse, FetchError<TError>>;
  };
}


function normalizeLocale(locale: string) {
  if (locale.toLocaleLowerCase() === 'en') {
    return 'en'
  } else {
    return 'zh-tw'
  }
}

function compileUrl(url: string, data: any = {}) {
  const locale = useNuxtApp().$i18n.locale
  if (data) {
    data.locale = normalizeLocale(locale.value)
  }
  return url.replace(/\/(:[^:?/]+\??)/g, field => {
    const [, key = '', optional = ''] = field.match(/^\/:(.+?)(\?)?$/) ?? []

    if (!data) {
      throw new Error(`"${url}" 需要資料輸入`)
    }

    const param = data[key] || ''
    if (!param && !optional) {
      throw new Error(`"${url}" 參數 "${key}" 為必填.`)
    }

    delete data[key]
    return param && `/${param}`
  })
}

function handleHeaders(defaultHeaders?: HeadersInit) {
  const header: Record<string, string> = {};
  if (Array.isArray(defaultHeaders)) {
    defaultHeaders.forEach(([k, v]) => {
      header[k] = v;
    });
    return header;
  } else if (defaultHeaders instanceof Headers) {
    defaultHeaders.forEach((v, k) => {
      header[k] = v;
    });
    return header;
  }

  return defaultHeaders || header;
}

/**
 * 防止資料快取
 * @see https://github.com/unjs/ofetch#onrequest-request-options-
 */
export function onRequestAddTimestamp({ request, options }: FetchContext) {
  options.query = options.query || {}
  options.query.t = (new Date()).getTime()
}
