/**
 * 頁面完成時，掛載事件追蹤器
 */
export default defineNuxtPlugin((nuxtApp) => {

  let tracker: ReturnType<typeof useEventTracker>
  const easterEgg = useEasterEgg('test-event-tracker')

  nuxtApp.hooks.hookOnce('page:finish', () => {
    tracker = useEventTracker()
  })

  /**
   * 為可點擊元素加上追蹤
   * 
   * Usage:
   * 
   * <div v-track:click="'foo,bar'">按鈕</div>
   * 
   * 點擊時會送出
   * - event_name: click
   * - page_title: foo
   * - page_location: bar
   */
  nuxtApp.vueApp.directive('track', {
    mounted(el, binding, vnode, prevVnode) {
      if (!binding.value) return
      if (!process.client) return

      const [location, title] = binding.value.split(',').reverse()
      const action = binding.arg ?? 'click'
      // 測試用外框
      if (window.location.host !== 'www.ugfunds.com') {
        watchEffect(() => {
          if (easterEgg.activated.value) {
            el.style.boxShadow = 'inset 0px 0px 2px 1px rgba(255, 165, 0, 0.60)'
            el.setAttribute('title', binding.value)
          } else {
            el.style.boxShadow = ''
            el.removeAttribute('title')
          }
        })
      }
      el.addEventListener(action, function () {
        if (easterEgg.activated.value) {
          console.log('Track', action, title, location)
        }
        if (tracker) {
          tracker.sendEvent(action, {
            page_title: title,
            page_location: location,
          })
        }
      })
    },
  })

})
