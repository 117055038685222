/**
 * 彩蛋機制
 * 把 A 元素拖曳到 B 就可以啟動特定的隱藏功能，通常用於內部測試
 * @param theme 此功能的主題名稱
 * @example
 *  const easterEgg = useEasterEgg('foo')
 * 
 *  <div v-bind="easterEgg.dragger">Drag me</div>
 *  <div v-bind="easterEgg.dropper">To here</div>
 * 
 *  <div>祕技已啟動：{{ easterEgg.activated }}</div>
 *  
 */

import { defu } from 'defu'

interface EasterEggOptions {
  devOnly?: boolean
}

const defaultOptions: EasterEggOptions = {
  devOnly: false
}

export default function useEasterEgg(theme: string, options: EasterEggOptions = defaultOptions) {

  options = defu(options, defaultOptions)

  const config = useRuntimeConfig()

  // 如果不是開發環境，則不啟用
  const cheatEnabled = options.devOnly ? !config.public.production : true
  const cheatActivated = useState(`easter-egg-${theme}`, () => ref(false))

  function startDraggableCheat(event: DragEvent) {
    event?.dataTransfer?.setData("text", 'EasterEgg');
  }

  function handleDraggableCheat(event: DragEvent) {
    if (cheatEnabled && event?.dataTransfer?.getData('text') === 'EasterEgg') {
      cheatActivated.value = !cheatActivated.value
    }
  }

  return {
    /** 彩蛋是否已啟動 */
    activated: cheatActivated,
    /** 
     * 註冊在要被拖曳的元素上
     * <div v-bind="easterEgg.dragger">Drag me</div>
     */
    dragger: {
      onDragstart: startDraggableCheat,
      draggable: true,
    },
    /** 
     * 註冊在要被放置的元素上
     * <div v-bind="easterEgg.dropper">To here</div>
     */
    dropper: {
      onDrop: handleDraggableCheat,
      onDragover: (e: DragEvent) => e.preventDefault()
    },
    onDragstart: startDraggableCheat,
    onDrop: handleDraggableCheat,
  }
}