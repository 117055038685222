
interface PageMetaOptions {
  /** 中文版頁面標題 */
  zh_title?: string
  /** 中文版頁面描述 */
  zh_description?: string
  /** 英文版頁面標題 */
  en_title?: string
  /** 英文版頁面描述 */
  en_description?: string
}

let pageTitle = ''

/** 取得目前設定的頁面標題 */
export function getPageTitle() { return pageTitle }

export function setPageTitle(title: string) {
  const combinedTitle = `${title} | UG Investment Advisers`
  pageTitle = title
  useHead({
    title: combinedTitle,
    meta: [
      {
        property: 'og:title',
        content: combinedTitle
      },
    ]
  })
}

export function setPageDescription(description: string) {
  useHead({
    meta: [
      {
        name: 'description',
        content: description,
      },
      {
        property: 'og:description',
        content: description,
      },
    ]
  })
}
/**
 * 設定頁面的 Ｍeta 資訊
 */
export function setPageMeta(options: PageMetaOptions) {
  const { locale } = useI18n()
  const {
    zh_title,
    zh_description,
    en_title,
    en_description,
  } = options

  if (locale.value === 'zh') {
    if (zh_title) setPageTitle(zh_title)
    if (zh_description) setPageDescription(zh_description)
  }
  if (locale.value === 'en') {
    if (en_title) setPageTitle(en_title)
    if (en_description) setPageDescription(en_description)
  }
}