import { createAction } from '@/utils/api'

/**
* 使用者活動紀錄
*/
export const trackEvent = createAction<
 // Response
 {
   session_id: string
 },
 {
  /** Event Name 事件名稱，長度為 40 字元 */
  en: string
  /** Engagement Time, number 互動時間 */
  et?: number
  /** Session ID 目前瀏覽行為階段的識別 */
  sid?: string
  /** usercode */
  uid?: string
  /** Data Location 頁面網址 */
  dl?: string
  /** Data Title 頁面標題 */
  dt?: string
 }
>('POST', '/api/:locale/track/events')