import Toast from '@/components/Toast.vue'
import { defu } from 'defu'

export interface ToastOptions {
  timeout?: number
  color?: string
}

/**
 * 使用 Toast 顯示簡短訊息
 * @param message
 * @param ToastOptions
 *  - timeout
 *  - color
 */
export function useToast(message: string, options: ToastOptions = {}) {
  const { vNode: toast, destroy } = useMount(Toast, {
    app: useNuxtApp().vueApp, props: defu(options, {
      timeout: 2000,
    })
  })

  // 顯示
  toast.component?.exposed?.show(message)

  // 關閉時自動摧毀
  whenever(() => toast.component?.exposed?.opened?.value === false, () => {
    destroy()
  })
}