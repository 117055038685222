/**
 * 登出會員並清除驗證 TOKEN
 */
import { memberSignout } from '@/api/members'

interface LogoutOptions {
  /** 只清除登入資訊 */
  eraseOnly: boolean
}

export const useLogout = async (options: LogoutOptions) => {
  const currentMember = useMember()
  const authorization = useAuthorization()
  const pagePermission = usePagePermission()

  // 只清除登入資訊
  if (options.eraseOnly) {
    currentMember.value = null
    authorization.value = null
    return
  }

  // 如果當前頁面需要登入
  if (pagePermission.value) {
    await navigateTo(useLocalePath()('/')) // 回到首頁
  }

  // 清除 session
  memberSignout()

  // GA: 使用者手動登出
  const gtag = useGtag()
  gtag('event', 'logout', {
    user_id: null
  })

  // 先離開頁面才登出
  currentMember.value = null
  authorization.value = null

  // Tracker: 使用者手動登出
  const tracker = useEventTracker()
  tracker.sendEvent('logout')

}