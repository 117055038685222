/**
 * 修正 `Array.at` 在舊版瀏覽器中不支援
 */
import 'core-js/proposals/relative-indexing-method'

import { createVuetify } from 'vuetify'
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // use google fonts
import { aliases, md } from 'vuetify/iconsets/md'

export default defineNuxtPlugin(app => {
  const vuetify = createVuetify({ 
    ssr: true,
    icons: {
      defaultSet: 'md',
      aliases,
      sets: {
        md,
      },
    },
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: '#1469CD',
            primaryLighten: '#2196F3',
            secondary: '#0B548F',
            accent: '#B71D67',
            error: '#D32F2F',
            info: '#039BE5',
            success: '#009688',
            warning: '#FB8C00',
          }
        },
      },
    },
    components: {
      VSkeletonLoader,
    },
  })
  app.vueApp.use(vuetify)
})