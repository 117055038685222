<template>
  <div>
    <NuxtLayout>
      <NuxtPage /> 
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()

// 如果路徑開頭是 /admin/ 就切換成 admin layout
if (route.fullPath.match(/^\/admin\//)){
  setPageLayout('admin')
}
</script>

<style lang="stylus">
// mobile 選單打開時，鎖住背景
html.no-scroll
  overflow hidden !important  
</style>