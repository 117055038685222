import { createVNode, render } from 'vue'
import { Component, App, VNode } from 'vue'

interface MountOptions <TProps>{
  props?: TProps;
  children?: unknown;
  app?: App;
  element?: HTMLElement | null;
}

/**
 * 動態掛載一個 Component
 * @see https://www.npmjs.com/package/mount-vue-component
 */
export function useMount<TProps>(component: Component, { props, children, element, app }: MountOptions<TProps> = {}) {
  let el = element

  let vNode: VNode | null = createVNode(component, props || {}, children)
  if (app && app._context) vNode.appContext = app._context
  if (el) render(vNode, el)
  else if (typeof document !== 'undefined' ) render(vNode, el = document.createElement('div'))

  const destroy = () => {
    if (el) render(null, el)
    el = null
    vNode = null
  }

  return { vNode, destroy, el }
}