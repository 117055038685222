import type { RouterConfig } from '@nuxt/schema'
// https://router.vuejs.org/api/interfaces/routeroptions.html

export default <RouterConfig>{
  routes: (routes) => [
    ...routes,
    {
      name: 'member-alias',
      path: '/:locale/members/:any(.*)',
      redirect: (route) => {
        return route.path.replace('members', 'member')
      }
    },
    {
      name: 'zh-alias',
      path: '/zh-tw/:any(.*)',
      redirect: (route) => {
        return route.path.replace('zh-tw', 'zh')
      }
    }
  ],
}