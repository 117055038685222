import type { Privilege } from '@/lib/privilege'

export enum MemberHolderType {
  Personal = '個人投資者',
  Joint = '聯名戶',
  Institutional = '機構投資者',
  Nominee = '專戶(Nominee)',
}

/** MemberGroup 對應到 Account Type */
export enum MemberGroup {
  Holder = 'holder',
  Agent = 'agent',
  Colleague = 'colleague',
  Admin = 'admin',
}

/** 可管理的子帳號角色定義 */
export enum MemberSubAccountRole {
  /** Agent 主帳號 */
  Main = 29,
  /** 客服人員 */
  SubService = 30,
  /** 交易人員 */
  SubDealer = 31,
  /** 帳務人員 */
  SubAccounting = 32,
  /** 帳務&交易人員 */
  SubDealerAccounting = 33,
  /** 管理員 */
  SubAdmin = 34,
}

/** Demo 帳號類型 */
export type MemberDemoAccountType = 'agent' | 'holder'

export interface IMember {
  id: any
  name: string
  client_id: string
  email: string
  phone: string
  group: MemberGroup
  holder_type?: MemberHolderType
  locales: Array<'en' | 'zh'>
  privileges: Privilege[]
  inStock: boolean
  isTraded: boolean
  isDemoAccount?: boolean
  agentService: boolean
  nominee: boolean
  reportableRate?: boolean // 可以回報「認購手續費加權平均」(nominee限定!)
}

export interface ISession {
  name: string
  platform: string
  expire_at: string
}

export interface ISignupForm {
  client_id: string
  client_name: string
  usercode: string
  password: string
  password_confirmation: string
  email: string
  phone: string
  confirmed?: boolean
}

export interface ISignupResponse {
  member?: IMember
  token?: string
  session?: ISession
  message: string
  pending?: number | boolean
}

export interface ISignupError {
  error: 1
  message: string
  errors?: {
    client_id?: string
    client_name?: string
    usercode?: string
    password?: string
    email?: string
    phone?: string
    confirmed?: string
  }
}

export interface ISigninResponse {
  member: IMember & { force: boolean }
  token: string
  session: ISession
}

export interface ISigninRequest {
  usercode: string
  password?: string | null
  force?: boolean | null
  agree?: boolean | null
  otp_code?: string | null
  platform?: string | null
  platform_version?: string | null
}

export type IOTPSigninError = {
  // 202
  otp_sent_to_email?: string;
  otp_sent_to_sms?: string;
  otp_checksum?: string;
  require_verification?: 'PASSWORD' | 'OTP';
} & {
  // 401
  otp_required?: number;
  otp_expired?: number;
};

export type ISigninError = {
  error: 1;
  message?: string;
  failed_attempts?: number;
  reason?: string; // 'RETRY_OVER_LIMIT';
} & IOTPSigninError;

export interface IManageableAccount {
  email: string
  name: string
  activated?: boolean
  agreed?: boolean
  viewonly?: boolean
}

/** 授權的 Sales */
export interface IAuthorizedSales {
  sale_id: number
  name: string
}

/** 可管理子帳號: 一般帳號 */
export interface IManageableSaleAccount extends IManageableAccount {
  accountType?: 'sale',
  role: MemberSubAccountRole
  authorized_sales: IAuthorizedSales[]
  notify_emails: string[]
}

/** 可管理子帳號: DEMO帳號 */
export interface IManageableDemoAccount extends IManageableAccount {
  accountType?: 'demo',
  type: MemberDemoAccountType
}

export type ManageableAccount = IManageableSaleAccount | IManageableDemoAccount

/** 判斷是 IManageableSaleAccount */
export function isManageableSaleAccount(v: any): v is IManageableSaleAccount {
  return v.accountType === 'sale' || !!(v.role && v.authorized_sales)
}

/** 判斷是 IManageableDemoAccount */
export function isManageableDemoAccount(v: any): v is IManageableDemoAccount {
  return v.accountType === 'demo' || !!(v.type)
}